import gsap  from "gsap";

export function iconAnimate(){
  // Sélectionnez l'élément .icon
const iconWrapper = document.querySelector('.prestation');
const iconElement = document.querySelectorAll('.icon');

// Créez une timeline GSAP pour organiser les animations en séquence
const timeline = gsap.timeline();

// Ajoutez une fonction pour déclencher l'animation lorsque l'élément .icon est au milieu de l'écran
function startAnimation() {
    // Définissez les propriétés initiales du cercle
    gsap.set([iconElement], { scale: 0, opacity: 0 });

    // Ajoutez une animation pour afficher progressivement le cercle
    timeline.to([iconElement], { scale: 1, opacity: 1, ease: 'bounce.out', duration: 0.5 });

    // Sélectionnez tous les chemins SVG de la classe .animate-icn
    const paths = document.querySelectorAll('.animate-icn path');

    // Parcourez chaque chemin SVG et définissez les propriétés initiales
    paths.forEach((path) => {
      gsap.set(path, {
          'stroke-dasharray': path.getTotalLength(),
          'stroke-dashoffset': path.getTotalLength(),
      });
    });


    // Ajoutez l'animation à chaque chemin SVG individuellement
      timeline.to([paths], { 'stroke-dashoffset': 0, duration: 1 });

}

// Créez une variable pour stocker l'état de l'animation
let animationPlayed = false;

// Écoutez l'événement de défilement de la fenêtre pour déclencher l'animation lorsque l'élément .icon est au milieu de l'écran
window.addEventListener('scroll', () => {
  if (!animationPlayed) { // Vérifiez si l'animation n'a pas déjà été jouée
    const elementPosition = iconWrapper.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    if (elementPosition.top < windowHeight / 2 && elementPosition.bottom > windowHeight / 2) {
      startAnimation();
      animationPlayed = true; // Définissez l'état de l'animation sur true pour qu'elle ne se joue qu'une seule fois
    }
  }
});
}
