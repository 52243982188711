export function contactForm(){
    
    const form = document.querySelector('form');
    const submitButton = document.querySelector('#submitButton');

    submitButton.addEventListener('click', function (event) {
        const errorFields = form.querySelectorAll('.error-field');
        errorFields.forEach(function (errorField) {
            errorField.textContent = '';
        });

        let hasError = false;
        const requiredFields = form.querySelectorAll('.required-field');
        requiredFields.forEach(function (field) {
            if (!field.value.trim()) {
                const errorField = field.nextElementSibling;
                errorField.textContent = 'Veuillez renseigner ce champ. Merci.';
                hasError = true;
                field.classList.add('error');
            } else {
                field.classList.remove('error');
            }
        });

        if (hasError) {
            event.preventDefault();
        } else {
            form.submit();
        }
    });
};
