import '../styles/index.scss';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import {horizontalLoopText} from "./horizontallooptext.js";
import {iconAnimate} from "./icn-animate.js";
import {Menu} from "./menu.js";
import {contactForm} from './contact';
import Swup from 'swup';

const swup = new Swup({
    containers: ["#swup"],
});

const menu = new Menu();

function init(){
    const currentPath = swup.currentPageUrl;
    const pathWithoutHtml = currentPath.replace(".html", ""); 

    if (document.querySelector('.swiper-home')) {
        const swiper = new Swiper('.swiper-home', {
            direction: 'horizontal',
            parallax: true,
            autoHeight: true,
            loop: true,
            autoplay: {
                delay: 2000,
            }
        });  
    }

    if (document.querySelector('.services-ticker-block')) {
        horizontalLoopText();
    }
    
    if (pathWithoutHtml === '/') {
        iconAnimate();
    }
    
    if (pathWithoutHtml === '/contact') {
        contactForm();
    }

    menu.activeClassLink(pathWithoutHtml);
} 


swup.hooks.on('page:view', () => {
    init();
});

swup.hooks.on('link:click', () => {
    menu.closeMenu();
});

init();